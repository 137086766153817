import { getConfig } from './config';
import { AuthUser, getUserId } from './user';
import { browserTracingIntegration, init, replayIntegration, setUser } from '@sentry/react';

export const initSentry = () => {
  const { sentryDsn, sentryReplaySampleRate, sentryTraceSampleRate, environment, apiUrl, version } = getConfig();

  if (environment === 'local') {
    console.info(`Skipping Sentry initialisation in local environment`);
    return;
  }
  if (!sentryDsn) return;

  const sentryEnvironment: 'staging' | 'uat' | 'production' = environment === 'development' ? 'staging' : environment;

  init({
    dsn: sentryDsn,
    tracePropagationTargets: [apiUrl],
    integrations: [browserTracingIntegration(), replayIntegration({ maskAllText: false, blockAllMedia: false })],
    tracesSampleRate: sentryTraceSampleRate,
    environment: sentryEnvironment,
    release: version,
    replaysSessionSampleRate: sentryReplaySampleRate,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const setSentryUser = (user: AuthUser, schoolName: string) => {
  setUser({
    username: `${user.userData.firstName} ${user.userData.lastName}`,
    email: user.email,
    id: getUserId(user),
    user_type: user.userData.roles[0],
    school: schoolName,
  });
};

import { sharedAppStore } from 'app/store';
import { Provider } from 'react-redux';
import { ThemeProvider as LegacyThemeProvider } from 'styled-components';
import legacyTheme from 'config/theme';
import { ThemeProvider } from '@mui/material';
import { theme } from 'customTheme';
import { LocaleProvider } from '../contexts/LocaleContext';
import SharedApp from './SharedApp';
import { ErrorBoundary } from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from '../auth0/Auth0ProviderWithNavigate';
import { AppProvider } from './AppContext';
import { SchoolProvider } from 'contexts/SchoolContext';

export const SharedEnroller = () => (
  <Provider store={sharedAppStore}>
    <LocaleProvider>
      <ThemeProvider theme={theme}>
        <LegacyThemeProvider theme={legacyTheme}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <AppProvider>
                <SchoolProvider>
                  <ErrorBoundary>
                    <SharedApp />
                  </ErrorBoundary>
                </SchoolProvider>
              </AppProvider>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </LegacyThemeProvider>
      </ThemeProvider>
    </LocaleProvider>
  </Provider>
);

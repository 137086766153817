declare global {
  interface Window {
    env: any;
  }
}

export type EnvType = {
  REACT_APP_ENV: string;
  REACT_APP_PAGE_TITLE: string;
  REACT_APP_URL: string;
  REACT_APP_API_URL: string;
  REACT_APP_BRAND_THEMING: string;
  REACT_APP_MARKETING_URL: string;
  REACT_APP_AGENCIES_URL: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_CALLBACK_URL: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_GOOGLE_PLACES_API_KEY: string;
  REACT_APP_INTERCOM_APP_ID?: string;
  REACT_APP_SENTRY_DSN?: string;
  REACT_APP_SENTRY_REPLAY_SAMPLE_RATE?: string;
  REACT_APP_SENTRY_TRACE_SAMPLE_RATE?: string;
  REACT_APP_GOOGLE_ANALYTICS_ID?: string;
  REACT_APP_LOGROCKET_ID?: string;
  REACT_APP_SEVIS_QUEUE_ACTION_ID?: string;
};

export const env: EnvType = { ...process.env, ...window.env };

import React from 'react';
import HTMLContent from 'workflow/components/HTMLContent';
import { Typography } from '@mui/material';

const ContentField = ({ value }) => {
  if (!value) {
    return (
      <div data-cy-field-type="content">
        <Typography color="error">Content not found</Typography>
      </div>
    );
  }

  return (
    <div data-cy-field-type="content">
      <HTMLContent message={value} />
    </div>
  );
};

export default ContentField;

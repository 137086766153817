import { School } from '../types/School';
import { AsyncStatus } from '../types/AsyncStatus';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer } from 'react';
import { useStatelessGet } from '../hooks/useStatelessGet';
import { ResponseEnvelope } from '../types/ResponseEnvelope';
import { useMatch } from 'react-router-dom';
import { useDeferredGet } from '../hooks/useDeferredGet';
import { useAuth0 } from '@auth0/auth0-react';

type SchoolAction =
  | { type: 'school reset' }
  | { type: 'start school update'; updates: Partial<School> }
  | { type: 'finish school update'; school: School }
  | { type: 'fail school update'; error: unknown }
  | { type: 'start school read' }
  | { type: 'finish school read'; school: School }
  | { type: 'fail school read'; error: unknown }
  | { type: 'start school list read' }
  | { type: 'finish school list read'; schools: School[] }
  | { type: 'fail school list read'; error: unknown };
type SchoolState = {
  status: AsyncStatus;
  schools: School[];
  school: School | null;
};
interface SchoolContextData {
  state: SchoolState;
  loadSchool: (slug: string) => Promise<void>;
  hasFeatures: (features: string[]) => boolean;
}

export const SchoolContext = createContext<SchoolContextData | undefined>(undefined);

const schoolReducer = (state: SchoolState, action: SchoolAction): SchoolState => {
  switch (action.type) {
    case 'school reset':
      return { ...state, school: null };
    case 'start school read':
    case 'start school list read':
    case 'start school update':
      return { ...state, status: 'pending' };
    case 'finish school read':
    case 'finish school update':
      return {
        ...state,
        status: 'success',
        school: action.school,
      };
    case 'finish school list read':
      return {
        ...state,
        status: 'success',
        schools: action.schools,
      };
    case 'fail school read':
    case 'fail school list read':
    case 'fail school update':
      console.error(action.error);
      return {
        ...state,
        status: 'error',
        school: null,
      };
  }
};

export const SchoolProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(schoolReducer, {
    status: 'idle',
    school: null,
    schools: [],
  });
  const { isAuthenticated } = useAuth0();
  const fetchSchoolList = useStatelessGet<School[]>(`/user-schools`);
  const fetchSchool = useDeferredGet<ResponseEnvelope<School>>({ skipAuth: !isAuthenticated });
  const isSchoolRoute = useMatch(':school/*');

  const sortNameAlphabetic = (a: School, b: School) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());

  const loadSchoolList = useCallback(async () => {
    dispatch({ type: 'start school read' });

    try {
      const response = await fetchSchoolList();
      dispatch({
        type: 'finish school list read',
        schools: response.sort(sortNameAlphabetic),
      });
    } catch (error) {
      dispatch({ type: 'fail school list read', error });
    }
  }, [fetchSchoolList]);

  const loadSchool = useCallback(
    async (slug) => {
      dispatch({ type: 'start school read' });

      try {
        const response = await fetchSchool(`/schools/${slug}`);
        dispatch({
          type: 'finish school read',
          school: response.data,
        });
      } catch (error) {
        dispatch({ type: 'fail school read', error });
      }
    },
    [fetchSchool],
  );

  const hasFeatures = useCallback(
    (features: string[]) => {
      if (!state.school?.features.length) return false;

      return features.some((feat) => state.school!.features.some((sf) => sf.name === feat));
    },
    [state.school],
  );

  useEffect(() => {
    if (isAuthenticated) {
      loadSchoolList();
    }
  }, [isAuthenticated, loadSchoolList]);

  useEffect(() => {
    if (isSchoolRoute === null) {
      dispatch({
        type: 'school reset',
      });
      return;
    }

    const contextIsIdle = state.status !== 'pending';

    if (state.schools.length) {
      const schoolFromRoute = state.schools.find((s) => s.slug === isSchoolRoute.params.school);
      const schoolIsDifferentFromLoaded = state.school?.slug !== schoolFromRoute?.slug;

      if (schoolFromRoute && schoolIsDifferentFromLoaded && contextIsIdle) {
        loadSchool(schoolFromRoute.slug);
      }
    } else if (!isAuthenticated && isSchoolRoute && contextIsIdle && !state.school) {
      loadSchool(isSchoolRoute.params.school);
    }
  }, [isAuthenticated, isSchoolRoute, loadSchool, state.school, state.school?.slug, state.schools, state.status]);

  return <SchoolContext.Provider value={{ state, loadSchool, hasFeatures }}>{children}</SchoolContext.Provider>;
};

export const useSchool = () => {
  const context = useContext(SchoolContext);

  if (context === undefined) {
    throw new Error('useSchool must be used within a SchoolProvider');
  }

  return context;
};

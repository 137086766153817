import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from 'app/AppContext';
import { getConfig } from 'utils/config';
import { SxProps } from '@mui/material';

/**
 * Adapted from MUI docs
 * @see https://mui.com/material-ui/react-autocomplete/#google-maps-place
 */

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface AddressAutocompleteProps {
  onChange: (address: Record<string, any>) => void;
  sx?: SxProps;
  disabled?: boolean;
  restrictToCountry?: string;
}

export const AddressAutocomplete = ({ onChange, sx, disabled = false, restrictToCountry }: AddressAutocompleteProps) => {
  const [value, setValue] = useState<Record<string, any> | null>(null);
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const [options, setOptions] = useState<Record<string, any>[]>([]);
  const loaded = useRef(false);
  const { googleMapsApiKey, apiUrl } = getConfig();

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  const fetchPredictions = useMemo(
    () =>
      debounce((request, callback) => {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const fetchPlaceDetails = async (googlePlaceId, appContext) => {
    const url = new URL(`${apiUrl}/place-details/${googlePlaceId}`);

    return fetch(url.toString(), {
      headers: { Authorization: `Bearer ${appContext.headers}` },
    })
      .then((r) => r.json())
      .catch((e) => {
        throw new Error(`Failed to fetch from api. Error: ${JSON.stringify(e)}`);
      });
  };

  useEffect(() => {
    let active = true;

    // @ts-expect-error TS(2339): Property 'google' does not exist on type 'Window &... Remove this comment to see the full error message
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        // @ts-expect-error TS(2339): Property 'google' does not exist on type 'Window &... Remove this comment to see the full error message
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let request: any = { input: inputValue };
    if (restrictToCountry) {
      request.componentRestrictions = { country: restrictToCountry };
    }

    fetchPredictions(request, (results) => {
      if (active) {
        let newOptions: Record<string, any>[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchPredictions, restrictToCountry]);

  return (
    <AppContext.Consumer>
      {(appContext) => (
        <Autocomplete
          id="google-places-autocomplete"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          size="small"
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText="No locations"
          sx={sx}
          disabled={disabled}
          onChange={(event, newValue: Record<string, any> | null) => {
            if (!newValue) return;
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            fetchPlaceDetails(newValue.place_id, appContext).then((details) => {
              onChange({
                googlePlaceId: newValue.place_id,
                addressLine1: newValue.structured_formatting.main_text,
                postCode: details.result.address_components.find((ac) => ac.types.includes('postal_code'))?.long_name,
                suburb: details.result.address_components.find((ac) => ac.types.includes('sublocality') || ac.types.includes('neighborhood'))?.long_name,
                city: details.result.address_components.find((ac) => ac.types.includes('locality') || ac.types.includes('administrative_area_level_2'))
                  ?.long_name,
                state: details.result.address_components.find((ac) => ac.types.includes('administrative_area_level_1'))?.long_name,
                // TODO: Decide how to match countries since we have our own table already...
                country: details.result.address_components.find((ac) => ac.types.includes('country'))?.long_name,
                latitude: details.result.geometry.location.lat,
                longitude: details.result.geometry.location.lng,
              });
            });
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" size="small" aria-labelledby="address-lookup-input-label" fullWidth />}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props} key={option.place_id}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: 'calc(100% - 44px)',
                      wordWrap: 'break-word',
                    }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{
                          fontWeight: part.highlight ? 'bold' : 'regular',
                        }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    </AppContext.Consumer>
  );
};

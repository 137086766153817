import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Country from 'components/Country/Country';
import { formatDistanceToNow } from 'date-fns';
import { useMountEffect } from 'hooks/useMountEffect';
import { usePageTitle } from 'contexts/PageTitleContext';
import { Button, Stack, Typography } from '@mui/material';
import { useStatelessGet } from 'hooks/useStatelessGet';
import { useDataTable } from '../../hooks/useDataTable';
import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';
import { PaginatedEnvelope } from '../../types/PaginatedEnvelope';
import { DataTable } from '../../components/DataTable/DataTable';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AddIcon from '@mui/icons-material/Add';
import { useSubHeader } from '../../components/AppLayout/AppLayout';

const columns: MRT_ColumnDef<MRT_RowData>[] = [
  {
    header: 'Nationality',
    Cell: function CountryCell({ row }) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Country name={row.original.nationality_name} code={row.original.nationality_iso} hideCode />
          <span>{row.original.nationality_name}</span>
        </div>
      );
    },
  },
  {
    header: 'Name',
    accessorFn: (originalRow) => `${originalRow.first_name} ${originalRow.last_name}`,
    Cell: function NameCell({ row }) {
      return (
        <Link
          state={{
            user: `${row.original.first_name} ${row.original.last_name}`,
          }}
          to={`/${row.original.school_slug}/enrolment-application/applicant/${row.original.applicant_id}`}
        >
          {row.original.first_name} {row.original.last_name}
        </Link>
      );
    },
  },
  {
    header: 'Email',
    accessorKey: 'email',
    Cell: ({ row }) => {
      return (
        <a href={`mailto: ${row.original.email}`} target="_blank" rel="noopener noreferrer">
          {row.original.email}
        </a>
      );
    },
  },
  {
    header: 'Provider',
    accessorKey: 'school_name',
    Cell: ({ row }) => {
      return (
        <Link
          state={{
            school: `${row.original.school_name}`,
          }}
          to={`/${row.original.school_slug}/pipeline`}
        >
          {row.original.school_name}
        </Link>
      );
    },
  },
  {
    header: 'Course',
    accessorKey: 'course',
  },
  {
    header: 'Status',
    accessorKey: 'step_description',
  },
  {
    header: 'Last Updated',
    accessorKey: 'updated_at',
    Cell: function LastUpdatedCell({ row }) {
      return (
        <>
          {formatDistanceToNow(new Date(row.original.updated_at), {
            addSuffix: true,
          })}
        </>
      );
    },
  },
];

export const Enrolments = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const getApplications = useStatelessGet<PaginatedEnvelope<any>>('/applications');
  const { setPageTitle } = usePageTitle();
  const { setSubHeader } = useSubHeader();

  useMountEffect(() => setPageTitle('Enrolments'));

  useEffect(() => {
    setSubHeader(
      <Grid container justifyContent="center" pt={2}>
        <Grid lg={12} xl={10}>
          <Stack direction="row" pb={3} spacing={1}>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              All Enrolments
            </Typography>
            <Button variant="contained" href="/my-schools">
              View all providers
            </Button>
          </Stack>
        </Grid>
      </Grid>,
    );
  }, [setSubHeader]);

  const { table } = useDataTable<MRT_RowData>({
    tableKey: 'agentsEnrolments',
    getTableData: getApplications,
    columnDefinitions: columns,
    globalFilter,
  });

  const tableEntityName = {
    singular: 'application',
    plural: 'applications',
  };

  const newButton = (
    <Button variant="contained" component={Link} to={`/my-schools?next=applicants/new`} startIcon={<AddIcon />}>
      New application
    </Button>
  );

  return (
    <Grid container justifyContent="center" pt={2}>
      <Grid lg={12} xl={10}>
        <DataTable table={table} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} entityName={tableEntityName} tableActions={() => newButton} />
      </Grid>
    </Grid>
  );
};

import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loader from 'components/Loader';
import { PaymentOption } from 'payment/components/PaymentOption';
import { DefaultPaymentOption } from 'payment/components/options/DefaultOption';
import paymentProviderMap from 'payment/paymentProviderMap';
import { hasBalanceDue as hasBalanceDueSelector } from 'invoices/invoiceSelectors';
import { getPaymentProviders, selectPaymentOption } from 'payment/paymentActions';
import { getInvoiceById } from 'invoices/invoiceActions';
import { useParams } from 'react-router-dom';
import { SchoolRouteParams } from 'app/routes/SchoolRoutes';
import { Typography } from '@mui/material';
import { useLocale } from '../../contexts/LocaleContext';
import { useSchool } from '../../contexts/SchoolContext';

interface PaymentOptionsProps {
  heading?: string;
  invoiceId: string;
  selectedPaymentSlug: string;
  readOnly?: boolean;
  sharedPage?: boolean;
  onChange?: (slug: string) => void;
}

export const PaymentOptions = ({
  heading = 'Choose how to pay this invoice:',
  invoiceId,
  selectedPaymentSlug,
  readOnly = false,
  sharedPage = false,
  onChange,
}: PaymentOptionsProps) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const {
    state: { school },
  } = useSchool();
  const { locale } = useLocale();
  const currencyCode = school?.currency_code || 'NZD';
  const dispatch = useAppDispatch();
  const invoiceFetchStatus = useAppSelector((state) => state.invoice.fetchStatus);
  const applicationId = useAppSelector((state) => state.invoice.applicationId);
  const hasBalanceDue = useAppSelector(hasBalanceDueSelector as any);
  const paymentProviders = useAppSelector(
    (state) => state.payment.paymentProviders,
    (a, b) => JSON.stringify(a) === JSON.stringify(b),
  );

  useEffect(() => {
    if (!paymentProviders?.length && applicationId) {
      dispatch(getPaymentProviders(applicationId, schoolSlug));
    }
  }, [dispatch, applicationId, paymentProviders, schoolSlug]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoiceById(invoiceId, schoolSlug, currencyCode, locale));
    }
  }, [currencyCode, dispatch, invoiceId, locale, schoolSlug]);

  const handleSelect = useCallback(
    (slug) => {
      dispatch(selectPaymentOption(slug));

      if (!sharedPage) {
        onChange?.(slug);
      }
    },
    [dispatch, onChange, sharedPage],
  );

  if (invoiceFetchStatus === 'loading' || !hasBalanceDue) {
    return <></>;
  }

  const onlyOneOption = paymentProviders && paymentProviders.length === 1;

  if (!paymentProviders || !paymentProviders.length) {
    return <Loader center small />;
  }

  return (
    <>
      {!onlyOneOption && (
        <Typography variant="h6" mb={2}>
          {heading}
        </Typography>
      )}
      {paymentProviders.map((option, index) => {
        if (!option.slug) {
          return <></>;
        }

        const SubComponent = paymentProviderMap[option.slug] ? paymentProviderMap[option.slug].optionComponent : DefaultPaymentOption;

        if (!paymentProviderMap[option.slug] && !paymentProviderMap[option.slug].optionComponent) {
          console.warn(`There is no sub component to render for the payment option of type "${option.type}"`);
        }

        return (
          <div key={`option-${index}`} data-cy="payment-option">
            <PaymentOption
              slug={option.slug}
              selected={option.slug === selectedPaymentSlug}
              onlyOption={onlyOneOption}
              onSelect={handleSelect}
              readOnly={!!readOnly}
            >
              <SubComponent {...option} readOnly={readOnly} onlyOption={onlyOneOption} />
            </PaymentOption>
          </div>
        );
      })}
    </>
  );
};

import { FormBuilderField } from '../types/FormBuilderField';
import { FormBuilderFieldType } from '../types/FormBuilderFieldType.enum';
import { ApiFormBuilderField } from '../types/api/ApiFormField';

export const convertApiFieldsToBuilderFields = (newFields: ApiFormBuilderField[]): FormBuilderField[] =>
  newFields.map((field) => {
    let options = field.new_options_format ?? [];
    let value = field.value !== null ? field.value : field.default_value;
    if (value === undefined) value = '';

    // special logic for createinvoice which stores the invoiceId as the value,
    // but requires a payload to create an invoice when submitting an action
    if ([FormBuilderFieldType.CreateInvoice].includes(field.field_type.name)) {
      options = { ...options, invoiceId: field.value };
      value = '';
    }
    return {
      id: field.id,
      value,
      label: field.label,
      slug: field.slug,
      type: field.field_type.name,
      options,
      legacyOptions: field.options, // TODO: Remove after ER-4884
      disabled: false,
      conditions: field.conditions,
      validationRules: field.validation_rules,
      applicationFieldMessages: field.application_field_messages ?? [],
      translationKey: field.translation_key,
      displayWidth: field.display_width,
    } as FormBuilderField;
  });

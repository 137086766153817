import { AppBar, Avatar, Box, Divider, Link, Stack, Toolbar, useTheme } from '@mui/material';
import HasAccess from 'permissions/HasAccess';
import { greenheartStaffRoles, UserRole } from 'permissions/constants/Roles';
import goLogo from './go-logo.svg';
import { isMenuItemActive } from '../../utils/isMenuItemActive';
import { ProfileMenu } from '../../components/Menu/ProfileMenu';
import { useSchool } from 'contexts/SchoolContext';

const getCurrentSchoolSlug = (school, schools): string => {
  const currentSchool = school ?? schools[0];
  return currentSchool ? currentSchool.slug : '';
};

const GreenheartMenuItem = ({ url, label }: { url: string; label: string }) => {
  const theme = useTheme();
  const menuLinkSx = (active: boolean) => ({
    px: 2,
    color: theme.palette.primary.main,
    height: '80px',
    lineHeight: '80px',
    fontSize: '2rem',
    borderBottomWidth: '5px',
    borderBottomStyle: 'solid',
    borderBottomColor: active ? theme.palette.primary.light : 'transparent',
  });

  return (
    <Link href={url} sx={menuLinkSx(isMenuItemActive(url))}>
      {label}
    </Link>
  );
};

const HostOrgPartnerMenu = () => {
  const {
    state: { school, schools },
  } = useSchool();

  const schoolSlug = getCurrentSchoolSlug(school, schools);

  return (
    <>
      <GreenheartMenuItem url={`/${schoolSlug}/positions`} label="Positions" />
      <HasAccess for="role" name={[UserRole.PartnerStaff, UserRole.PartnerAdmin]}>
        <GreenheartMenuItem url={`/${schoolSlug}/participants`} label="Participants" />
        <GreenheartMenuItem url={`/${schoolSlug}/host-orgs`} label="Host Orgs" />
      </HasAccess>
    </>
  );
};

const ParticipantMenu = () => {
  const {
    state: { school, schools },
  } = useSchool();

  const schoolSlug = getCurrentSchoolSlug(school, schools);

  const url = schoolSlug ? `/${schoolSlug}/pipeline` : '/';
  return <GreenheartMenuItem url={url} label="Placement" />;
};

const GreenheartStaffMenu = () => {
  const {
    state: { school, schools },
  } = useSchool();

  const schoolSlug = getCurrentSchoolSlug(school, schools);

  return (
    <>
      <GreenheartMenuItem url={`/${schoolSlug}/positions`} label="Positions" />
      <GreenheartMenuItem url={`/${schoolSlug}/participants`} label="Participants" />
      <HasAccess for="role" name={[UserRole.GreenheartAdmin]}>
        <GreenheartMenuItem url={`/${schoolSlug}/sevis`} label="SEVIS" />
      </HasAccess>
    </>
  );
};

export const GreenheartHeader = () => {
  const theme = useTheme();
  const bgcolor = theme.palette.background.paper;
  const color = theme.palette.getContrastText(bgcolor);

  return (
    <AppBar sx={{ color, bgcolor }}>
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          <Avatar src={goLogo} alt="Brand logo" variant="square" sx={{ width: '64.5px', height: '50px' }} />
          <Box px={3}>
            <Divider orientation="vertical" variant="middle" sx={{ height: '32px', borderWidth: '1px' }} />
          </Box>
          <Box flexGrow="1" />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            <HasAccess for="role" name={[UserRole.HostOrganizationStaff, UserRole.HostOrganizationAdmin, UserRole.PartnerStaff, UserRole.PartnerAdmin]}>
              <HostOrgPartnerMenu />
            </HasAccess>
            <HasAccess for="role" name={[UserRole.Applicant]}>
              <ParticipantMenu />
            </HasAccess>
            <HasAccess for="role" name={greenheartStaffRoles}>
              <GreenheartStaffMenu />
            </HasAccess>
            <ProfileMenu />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

import * as ActionTypes from 'app/constants/ActionTypes';
import * as ErrorActions from 'app/actions/errorActions';
import invoiceAPI from 'invoices/api/invoiceAPI';
import paymentsAPI from 'payment/paymentsAPI';

export function voidPayment(paymentId, slug) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.INVOICE_VOID_PAYMENT,
    });

    paymentsAPI
      .PUT({
        params: {
          slug,
          id: paymentId,
          action: 'void',
        },
      })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.INVOICE_VOID_PAYMENT_RESPONSE,
            payload: {
              success: true,
              invoice: response.entity.data,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.INVOICE_VOID_PAYMENT_RESPONSE,
          payload: {
            success: false,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}

export function getInvoiceById(id, slug, currencyCode, locale) {
  return function (dispatch, getState) {
    dispatch({
      type: ActionTypes.GET_INVOICE_BY_ID,
    });

    invoiceAPI
      .GET({ params: { slug, id } })
      .then((response) => {
        if (response.status.code === 200) {
          dispatch({
            type: ActionTypes.GET_INVOICE_BY_ID_RESPONSE,
            payload: {
              success: true,
              invoice: response.entity.data,
              currencyCode,
              locale,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_INVOICE_BY_ID_RESPONSE,
          payload: {
            success: false,
            error,
          },
        });

        ErrorActions.show(error)(dispatch);
      });
  };
}

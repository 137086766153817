import React from 'react';
import { Params, Route, Routes, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import { useIntercom } from 'hooks/analytics/useIntercom';
import { AppLayout } from '../components/AppLayout/AppLayout';

const SharedInvoice = React.lazy(() => import(/* webpackChunkName: 'sharedinvoice' */ 'views/Shared/SharedInvoice'));
const SharedInvoicePayment = React.lazy(() => import(/* webpackChunkName: 'sharedinvoice' */ 'views/Shared/SharedInvoicePayment'));

export interface SharedInvoiceRouteParams extends Params {
  slug: string;
  id: string;
}

const SharedSchoolRoutes = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="student-invoice/:id" element={<SharedInvoice />} />
        <Route path="student-invoice/:id/payment" element={<SharedInvoicePayment />} />
      </Route>
    </Routes>
  );
};

export const SharedApp = () => {
  const location = useLocation();
  useIntercom();

  return (
    <React.Suspense fallback={<Loader center />}>
      <Routes location={location}>
        <Route path=":slug/*" element={<SharedSchoolRoutes />} />
      </Routes>
    </React.Suspense>
  );
};

export default SharedApp;

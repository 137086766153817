import { Avatar, Chip, Dialog, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import ProductsAccess from '../../permissions/ProductsAccess';
import { ProductLevel } from '../../permissions/constants/ProductLevels';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import DescriptionIconOutlined from '@mui/icons-material/DescriptionOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LinkIcon from '@mui/icons-material/Link';
import { ApplicationDocuments } from '../../profile/ApplicationDocuments';
import { ApplicationNotes } from '../../profile/ApplicationNotes';
import { ApplicationParticulars } from '../../profile/ApplicationParticulars';
import { EditApplicant } from '../../profile/editApplicant/EditApplicant';
import { TransferOwnershipDialog } from '../../profile/transferOwnership/TransferOwnershipDialog';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { UserGroup, Permission, UserRole } from '../../permissions/constants/Roles';
import { getStudentStatusColor } from '../../app/constants/StudentStatuses';
import { APPLICATION_STATUS_LABELS, APPLICATION_STATUS_ENROLLED, APPLICATION_STATUS_WITHDRAWN } from '../../app/constants/ApplicationStatuses';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { usePermissions } from '../../contexts/PermissionContext';
import { useMatch, useParams } from 'react-router-dom';
import { SchoolRouteParams } from '../../app/routes/SchoolRoutes';
import { Application } from '../../types/Application';
import { intlFormat, IntlFormatFormatOptions, parseISO } from 'date-fns';
import { Applicant } from '../../types/Applicant';
import { useLocale } from '../../contexts/LocaleContext';
import { useGet } from 'hooks/useGet';
import { ResponseEnvelope } from 'types/ResponseEnvelope';
import { getErrorMessage } from 'utils/errors';
import { useNotificationMessages } from 'hooks/useNotificationMessages';
import { FileApiFormBuilderField } from 'components/FormBuilder/types/api/ApiFormField';
import { File } from 'types/File';
import { ApplicantSummaryPhoto } from './ApplicantSummaryPhoto';
import { getConfig } from 'utils/config';

interface ApplicantSummaryProps {
  application?: Application | null;
  applicant: Applicant;
}

export const ApplicantSummary = ({ application = null, applicant }: ApplicantSummaryProps) => {
  const { slug: schoolSlug } = useParams() as SchoolRouteParams;
  const { hasRole, hasGroup, hasPermission } = usePermissions();
  const { localeCode } = useLocale();
  const theme = useTheme();
  const [moreApplicantActionsMenuAnchorEl, setMoreApplicantActionsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = useState<
    'applicationLead' | 'applicationParticulars' | 'applicantDetails' | 'applicantStatus' | 'reassignAgent' | 'applicationDocuments' | 'applicationNotes' | null
  >(null);
  const isApplicantAllEnrolmentsPage = useMatch(`/${schoolSlug}/enrolment-application/applicant/${applicant?.id}/`);
  const { showErrorMessage } = useNotificationMessages();
  const { appBrandTheme } = getConfig();

  type GhSummary = {
    id: string;
    contactNo: string | null;
    accountNo: string | null;
    startDate: string | null;
    endDate: string | null;
    positionName: string | null;
    accountName: string | null;
    sevisId: string;
  };
  const [, getGhSummary] = useGet<ResponseEnvelope<GhSummary>>(`/schools/${schoolSlug}/applications/${application?.id}/gh-summary`);
  const [ghSummary, setGhSummary] = useState<GhSummary | null>(null);

  // TODO: change this to not use branding once we have a way to differentiate GH applications from non-GH ones.
  const isGhApplicant = appBrandTheme === 'greenheart';

  const fetchGhSummary = useCallback(async () => {
    try {
      const response = await getGhSummary();
      setGhSummary(response.data);
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  }, [getGhSummary, showErrorMessage]);

  useEffect(() => {
    if (application?.id) {
      fetchGhSummary();
    }
  }, [fetchGhSummary, application]);

  if (!applicant) return <></>;

  const dateFormat: IntlFormatFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const studentStatus = applicant.student_status?.status;
  const studentStatusColor = studentStatus ? getStudentStatusColor(studentStatus) : 'inherit';
  const studentStatusContrastText = studentStatus ? theme.palette.getContrastText(studentStatusColor) : 'inherit';
  const creatorFullName = `${applicant.created_by?.first_name} ${applicant.created_by?.last_name}`;

  const dateOfBirth = applicant.date_of_birth ? intlFormat(parseISO(applicant.date_of_birth), dateFormat, { locale: localeCode }) : null;
  const studyStart = application?.start_date ? intlFormat(parseISO(application?.start_date), dateFormat, { locale: localeCode }) : null;
  const studyEnd = application?.end_date ? intlFormat(parseISO(application?.end_date), dateFormat, { locale: localeCode }) : null;
  const studyDates = `${studyStart} - ${studyEnd}`;
  const applicationStatusLabel = application?.status ? APPLICATION_STATUS_LABELS[application?.status] : null;
  const applicationStatusTooltip = (hasGroup([UserGroup.Institutional]) && application?.status_update_comment) || '';
  const applicationStatusColor =
    application?.status === APPLICATION_STATUS_ENROLLED ? 'success' : application?.status === APPLICATION_STATUS_WITHDRAWN ? 'error' : 'warning';

  // GH Special
  const positionStartDate = ghSummary?.startDate ? intlFormat(parseISO(ghSummary?.startDate), dateFormat, { locale: localeCode }) : null;
  const positionEndDate = ghSummary?.endDate ? intlFormat(parseISO(ghSummary?.endDate), dateFormat, { locale: localeCode }) : null;
  const positionDates = `${positionStartDate} - ${positionEndDate}`;
  const applicationFields = application?.workflow.stages.flatMap((stage) => stage.steps.flatMap((step) => step.fields));
  const applicantPhotoField = applicationFields?.find((field) => field.name === 'applicant_photo_upload') as FileApiFormBuilderField;
  const applicantPhotoFile = applicantPhotoField?.value as unknown as File;
  const sevisId = ghSummary?.sevisId;

  const isMoreApplicantActionsMenuOpen = Boolean(moreApplicantActionsMenuAnchorEl);
  const onMoreApplicantActionsClick: MouseEventHandler<HTMLButtonElement> = (event) => setMoreApplicantActionsMenuAnchorEl(event.currentTarget);
  const onMoreApplicantActionsClose: MouseEventHandler<HTMLElement> = () => setMoreApplicantActionsMenuAnchorEl(null);

  const renderViewAllEnrolmentsMenuLink = !hasRole([UserRole.SharedApplicant, UserRole.Applicant]) && !isApplicantAllEnrolmentsPage && !isGhApplicant;
  const renderEditDetailsMenuLink = applicant.editable && !isGhApplicant;
  const renderEditStatusMenuLink = Boolean(applicant.student_status) && applicant.editable && hasGroup([UserGroup.Institutional]) && !isGhApplicant;
  const renderReassignAgentMenuLink =
    applicant.created_by && hasGroup([UserGroup.Institutional]) && hasPermission([Permission.TransferOwnership]) && !isGhApplicant;
  const renderDocumentsMenuLink = !!application;
  const renderMoreApplicantActionsMenuButton =
    renderViewAllEnrolmentsMenuLink || renderEditDetailsMenuLink || renderEditStatusMenuLink || renderReassignAgentMenuLink || renderDocumentsMenuLink;
  const renderInstitutionMenuLinks = !!application && hasGroup([UserGroup.Institutional]) && !isGhApplicant;
  const renderParticularsMenuLink = renderInstitutionMenuLinks && hasRole([UserRole.InstitutionalStaff, UserRole.InstitutionalAdmin]);
  const renderLeadsMenuLink = renderInstitutionMenuLinks && !!application?.lead_id;
  const renderCourse = Boolean(application?.course);
  const renderStudyDates = Boolean(studyStart && studyEnd);
  const renderDoB = Boolean(applicant.date_of_birth);
  const renderCreatedByAgentIcon = applicant.created_by?.roles?.includes(UserRole.Agent);
  const renderCreatedByParentIcon = applicant.created_by?.roles?.includes(UserRole.Parent);
  const renderCreatedBy = Boolean(renderCreatedByAgentIcon || renderCreatedByParentIcon);
  const renderCreatedByLabel = `${renderCreatedByAgentIcon ? 'Agent' : 'Parent'}`;
  const renderAgencyName = Boolean(applicant.created_by?.agency?.name);
  const renderPositionName = Boolean(ghSummary?.positionName);
  const renderPositionDates = Boolean(positionStartDate && positionEndDate);
  const renderGhAccountName = Boolean(ghSummary?.accountName);
  const renderGhContactNo = Boolean(ghSummary?.contactNo);
  const renderGhApplicantPhoto = Boolean(applicantPhotoFile);

  const onEditDetailsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicantDetails');
  };
  const onEditStatusMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicantStatus');
  };
  const onReassignAgentMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('reassignAgent');
  };
  const onDocumentsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationDocuments');
  };
  const onNotesMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationNotes');
  };
  const onParticularsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationParticulars');
  };
  const onLeadsMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoreApplicantActionsClose(event);
    setOpenDialog('applicationLead');
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {renderGhApplicantPhoto && <ApplicantSummaryPhoto file={applicantPhotoFile} size="64px" />}
      <Stack direction="column">
        <Stack direction="row" width="100%" spacing={2} alignItems="center">
          <Typography variant="h4" component="h1" color="primary" textTransform="capitalize">
            {applicant.first_name} {applicant.last_name}
          </Typography>
          {sevisId && <Chip color="primary" variant="outlined" label={`SEVIS ID: ${sevisId}`} />}
          {studentStatus && (
            <ProductsAccess disallow={[ProductLevel.EnrollerRecruit]}>
              <Chip
                label={studentStatus}
                size="small"
                sx={{
                  bgcolor: studentStatusColor,
                  color: studentStatusContrastText,
                }}
              />
            </ProductsAccess>
          )}
          {renderMoreApplicantActionsMenuButton && (
            <Tooltip title="More applicant actions">
              <IconButton aria-label="more applicant actions" onClick={onMoreApplicantActionsClick}>
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          )}
          <Menu anchorEl={moreApplicantActionsMenuAnchorEl} open={isMoreApplicantActionsMenuOpen} onClose={onMoreApplicantActionsClose}>
            {renderViewAllEnrolmentsMenuLink && (
              <MenuItem href={`/${schoolSlug}/enrolment-application/applicant/${applicant.id}`} onClick={onMoreApplicantActionsClose} {...{ component: Link }}>
                <ListItemIcon>
                  <VisibilityOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>View all enrolments</ListItemText>
              </MenuItem>
            )}
            {renderEditDetailsMenuLink && (
              <MenuItem onClick={onEditDetailsMenuClick}>
                <ListItemIcon>
                  <EditOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit details</ListItemText>
              </MenuItem>
            )}
            {renderEditStatusMenuLink && (
              <ProductsAccess disallow={[ProductLevel.EnrollerRecruit]}>
                <MenuItem onClick={onEditStatusMenuClick}>
                  <ListItemIcon>
                    <TrafficOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit status</ListItemText>
                </MenuItem>
              </ProductsAccess>
            )}
            {renderReassignAgentMenuLink && (
              <MenuItem onClick={onReassignAgentMenuClick}>
                <ListItemIcon>
                  <SupervisorAccountOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Re-assign Agent</ListItemText>
              </MenuItem>
            )}
            {renderDocumentsMenuLink && (
              <MenuItem onClick={onDocumentsMenuClick}>
                <ListItemIcon>
                  <DescriptionIconOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Documents</ListItemText>
              </MenuItem>
            )}
            {renderInstitutionMenuLinks && (
              <MenuItem onClick={onNotesMenuClick}>
                <ListItemIcon>
                  <EditNoteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Notes</ListItemText>
              </MenuItem>
            )}
            {renderParticularsMenuLink && (
              <MenuItem onClick={onParticularsMenuClick}>
                <ListItemIcon>
                  <LinkIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Particulars</ListItemText>
              </MenuItem>
            )}
            {renderLeadsMenuLink && (
              <MenuItem onClick={onLeadsMenuClick}>
                <ListItemIcon>
                  <EditNoteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Enquiry notes</ListItemText>
              </MenuItem>
            )}
          </Menu>
          <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicationDocuments'} onClose={() => setOpenDialog(null)}>
            <ApplicationDocuments onToggle={() => setOpenDialog(null)} applicationId={application?.id} />
          </Dialog>
          <Dialog fullWidth maxWidth="md" open={openDialog === 'applicationNotes'} onClose={() => setOpenDialog(null)}>
            <ApplicationNotes onToggle={() => setOpenDialog(null)} recipient="applications" recipientId={application?.id} dialogTitle="Application notes" />
          </Dialog>
          <Dialog fullWidth maxWidth="md" open={openDialog === 'applicationLead'} onClose={() => setOpenDialog(null)}>
            <ApplicationNotes onToggle={() => setOpenDialog(null)} recipient="leads" recipientId={application?.lead_id} dialogTitle="Enquiry notes" />
          </Dialog>
          <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicationParticulars'} onClose={() => setOpenDialog(null)}>
            <ApplicationParticulars onToggle={() => setOpenDialog(null)} />
          </Dialog>
          <Dialog fullWidth maxWidth="lg" open={openDialog === 'applicantDetails' || openDialog === 'applicantStatus'} onClose={() => setOpenDialog(null)}>
            <EditApplicant onToggle={() => setOpenDialog(null)} defaultTab={openDialog === 'applicantStatus' ? 1 : 0} />
          </Dialog>
          <Dialog fullWidth maxWidth="lg" open={openDialog === 'reassignAgent'} onClose={() => setOpenDialog(null)}>
            <TransferOwnershipDialog onClose={() => setOpenDialog(null)} />
          </Dialog>
        </Stack>
        <Stack direction="row" columnGap={2.5} rowGap={0.5} flexWrap="wrap">
          {applicant.nationality && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={`/images/flag-icons/flags/4x3/${applicant.nationality.iso_3166_2.toLowerCase()}.svg`}
                variant="rounded"
                sx={{ width: 20, height: 20, fontSize: '14px' }}
              >
                {applicant.nationality.iso_3166_2}
              </Avatar>
              <Typography color="secondary" fontSize="small">
                {applicant.nationality.name}
              </Typography>
            </Stack>
          )}
          {renderDoB && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <CakeOutlinedIcon color="secondary" />
              <Typography color="secondary" fontSize="small">
                {dateOfBirth}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={0.5} alignItems="center">
            <PersonOutlinedIcon color="secondary" />
            <Typography color="secondary" fontSize="small">
              {applicant.gender}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <EmailOutlinedIcon color="secondary" />
            <Typography color="secondary" fontSize="small">
              {applicant.email}
            </Typography>
          </Stack>
          {renderCreatedBy && (
            <Tooltip
              arrow
              title={
                <>
                  {renderAgencyName && <Typography color="inherit" fontSize="small">{`Agency name: ${applicant.created_by?.agency?.name}`}</Typography>}
                  <Typography color="inherit" fontSize="small">{`${renderCreatedByLabel} name: ${creatorFullName}`}</Typography>
                  <Typography color="inherit" fontSize="small">{`${renderCreatedByLabel} email: ${applicant.created_by?.email}`}</Typography>
                </>
              }
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                {renderCreatedByAgentIcon && <BusinessOutlinedIcon color="secondary" />}
                {renderCreatedByParentIcon && <PeopleIcon color="secondary" />}
                <Typography color="secondary" fontSize="small" textTransform="capitalize">
                  {applicant.created_by?.agency?.name || creatorFullName}
                </Typography>
              </Stack>
            </Tooltip>
          )}
          {renderGhAccountName && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <BusinessOutlinedIcon color="secondary" />
              <Typography color="secondary" fontSize="small">
                {ghSummary!.accountName}
              </Typography>
            </Stack>
          )}
          {(renderCourse || renderPositionName) && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              {renderCourse && <SchoolOutlinedIcon color="secondary" />}
              {renderPositionName && <WorkOutlineIcon color="secondary" />}
              <Typography color="secondary" fontSize="small">
                {application?.course || ghSummary!.positionName}
              </Typography>
              <Tooltip title={applicationStatusTooltip} arrow>
                <Chip label={applicationStatusLabel} color={applicationStatusColor} variant="outlined" size="small" />
              </Tooltip>
              {renderGhContactNo && (
                <Tooltip title="Contact ID" arrow>
                  <Chip label={ghSummary!.contactNo} variant="outlined" size="small" />
                </Tooltip>
              )}
            </Stack>
          )}
          {(renderStudyDates || renderPositionDates) && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <DateRangeOutlinedIcon color="secondary" />
              <Typography color="secondary" fontSize="small">
                {renderStudyDates ? studyDates : positionDates}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

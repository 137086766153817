import { AppBar, Link, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import goLogo from './go-logo.svg';

export const GreenheartFooter = () => {
  const theme = useTheme();

  // TODO: Stack footer elements vertically instead of horizontally on small screens
  // const isMediumOrAbove = useMediaQuery(theme.breakpoints.up("md"));

  const linkSx = {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  };
  const copyrightYear = new Date().getFullYear();

  return (
    <AppBar
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor: '#fff',
        width: '100%',
        borderTop: `solid 1px ${grey[200]}`,
        top: 'auto',
        bottom: 0,
        zIndex: 1250,
      }}
      component="footer"
      position="relative"
      elevation={0}
    >
      <Stack direction="row" alignItems="center">
        <img src={goLogo} alt="Greenheart Logo" style={{ height: '22px', objectFit: 'contain' }} />
        <Typography variant="body2" sx={{ ml: 2, color: grey[800], fontWeight: 500 }}>
          &copy;{copyrightYear} Greenheart Org. All rights reserved
        </Typography>
      </Stack>

      <Stack direction="row" mr={7} spacing={3}>
        <Link
          href="https://learn.zohopublic.com/external/manual/intern-trainee-participant-manual/article/contact-greenheart?p=16b8c4dedd0acdcd46e873dfbeeb0ab67b1839b81d66f7a50d165e1c99172300"
          target="_blank"
          rel="noopener noreferrer"
          sx={linkSx}
        >
          <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
            Emergency Information
          </Typography>
        </Link>
        <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={linkSx}>
          <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
            Help Center
          </Typography>
        </Link>
        <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx }}>
          <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
            Terms of Use
          </Typography>
        </Link>
        <Link href="about:blank" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx }}>
          <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
            Privacy Policy
          </Typography>
        </Link>
      </Stack>
    </AppBar>
  );
};
